export const nfts = [
  {
    _id: "lucg8jdpl4q8est2dn",
    name: "Becky Schumm",
    user_id: "lucg8jdrjmz0156je1j",
    artist_name1: "Jeff Wiza I",
    video: "http://reina.org",
    description: "Dolores autem odit perspiciatis.",
    metauri: "http://leone.info",
    token_id: 3747,
    chainId: "80001",
    supply: 14,
    royalty: 5753,
    wallet_address: "0x8ac8cc3f717d7f7a0bc2c6a80be4be407604def4",
    status: true,
    isEmote: false,
    rid: "rid",
    isPaid: false,
    video_duration: 24,
    category: "Grocery",
    likeCount: "0",
    watchCount: "0",
    is_blocked: false,
    view_count: 0,
    view_users: [],
    createdAt: "2024-01-12T09:53:26.263Z",
    updatedAt: "2023-09-23T15:44:02.917Z",
    __v: 0,
  },
  {
    _id: "lucg8jeesnwsl8mgf7",
    name: "Carol Kihn",
    user_id: "lucg8jeed117k32zcx9",
    artist_name1: "Dewey Farrell",
    video: "http://roxane.org",
    description: "Laboriosam omnis animi occaecati qui ut.",
    metauri: "http://evangeline.info",
    token_id: 8395,
    chainId: "80001",
    supply: 717,
    royalty: 8148,
    wallet_address: "0xfd465c6e0fbe5b86f9fbadf2deaaa7dd5435efaa",
    status: false,
    isEmote: true,
    rid: "rid",
    isPaid: true,
    video_duration: 22,
    category: "Shoes",
    likeCount: "0",
    watchCount: "0",
    is_blocked: true,
    view_count: 0,
    view_users: [],
    createdAt: "2023-02-20T12:50:42.851Z",
    updatedAt: "2023-12-31T18:22:49.301Z",
    __v: 0,
  },
  {
    _id: "lucg8jeeorsqjgc5rvb",
    name: "Israel Kemmer",
    user_id: "lucg8jeeuph8q67b0u",
    artist_name1: "Kelvin Runolfsson",
    video: "http://louisa.net",
    description:
      "Asperiores nisi ad recusandae qui tempora molestiae placeat doloremque.",
    metauri: "http://kennedy.org",
    token_id: 1528,
    chainId: "80001",
    supply: 352,
    royalty: 7324,
    wallet_address: "0xe27eb4fffe87ae2975bf1428dec0861f7d7dcfaa",
    status: false,
    isEmote: true,
    rid: "rid",
    isPaid: true,
    video_duration: 31,
    category: "Health",
    likeCount: "0",
    watchCount: "0",
    is_blocked: true,
    view_count: 0,
    view_users: [],
    createdAt: "2023-03-18T04:19:58.584Z",
    updatedAt: "2023-12-29T14:54:02.551Z",
    __v: 0,
  },
  {
    _id: "lucg8jefpe2wwg0psb",
    name: "Jaime Labadie",
    user_id: "lucg8jefrt50070n6oi",
    artist_name1: "Mr. Evelyn Waelchi",
    video: "https://hertha.biz",
    description: "Dolorem suscipit et labore ea aliquid.",
    metauri: "http://tina.net",
    token_id: 641,
    chainId: "80001",
    supply: 931,
    royalty: 4144,
    wallet_address: "0x7390eebaaf83228bcfbdbe392c50eaaeff6fc11b",
    status: true,
    isEmote: false,
    rid: "rid",
    isPaid: false,
    video_duration: 4,
    category: "Baby",
    likeCount: "0",
    watchCount: "0",
    is_blocked: true,
    view_count: 0,
    view_users: [],
    createdAt: "2024-06-18T21:46:31.112Z",
    updatedAt: "2024-04-02T22:19:53.812Z",
    __v: 0,
  },
  {
    _id: "lucg8jefbo3givb0mph",
    name: "Cary Wisozk",
    user_id: "lucg8jefk8588y6gwhf",
    artist_name1: "Irvin Halvorson",
    video: "https://giovanny.name",
    description: "Qui pariatur fugit veritatis eos aliquam cumque.",
    metauri: "https://damien.com",
    token_id: 8859,
    chainId: "80001",
    supply: 905,
    royalty: 3310,
    wallet_address: "0xfab63c1c7cbeadf2e0f9640172c3dceebf5fe68f",
    status: false,
    isEmote: false,
    rid: "rid",
    isPaid: false,
    video_duration: 30,
    category: "Toys",
    likeCount: "0",
    watchCount: "0",
    is_blocked: false,
    view_count: 0,
    view_users: [],
    createdAt: "2023-08-27T02:55:04.927Z",
    updatedAt: "2024-04-09T15:24:04.649Z",
    __v: 0,
  },
  {
    _id: "lucg8jefcafu222r44f",
    name: "Marcella Greenfelder",
    user_id: "lucg8jefw6p6x1ios1f",
    artist_name1: "Kristen Marvin",
    video: "http://payton.org",
    description: "Quia tempora sed accusantium vitae aperiam et maiores.",
    metauri: "http://addie.org",
    token_id: 7560,
    chainId: "80001",
    supply: 532,
    royalty: 5495,
    wallet_address: "0xaa0f21fb245a2a37e22154eb4bc96b8230f760f7",
    status: false,
    isEmote: true,
    rid: "rid",
    isPaid: false,
    video_duration: 4,
    category: "Music",
    likeCount: "0",
    watchCount: "0",
    is_blocked: false,
    view_count: 0,
    view_users: [],
    createdAt: "2023-08-27T22:23:35.705Z",
    updatedAt: "2023-07-06T03:15:09.715Z",
    __v: 0,
  },
  {
    _id: "lucg8jeg1d9gfh6cvwl",
    name: "Andrea Jones DDS",
    user_id: "lucg8jeg1l9krdg91hv",
    artist_name1: "Drew Hand",
    video: "http://gene.biz",
    description:
      "Dolorem voluptas maiores est maxime exercitationem dolorum molestias mollitia.",
    metauri: "https://ari.com",
    token_id: 8554,
    chainId: "80001",
    supply: 824,
    royalty: 3530,
    wallet_address: "0xa74cabb4fbb4797de27b77a9884dd06f3ffc5fff",
    status: false,
    isEmote: true,
    rid: "rid",
    isPaid: true,
    video_duration: 44,
    category: "Tools",
    likeCount: "0",
    watchCount: "0",
    is_blocked: true,
    view_count: 0,
    view_users: [],
    createdAt: "2024-03-14T21:14:21.896Z",
    updatedAt: "2024-08-03T10:50:12.469Z",
    __v: 0,
  },
  {
    _id: "lucg8jeiz71glmw6mcf",
    name: "Mrs. Gerardo Effertz",
    user_id: "lucg8jeiennorg21bwj",
    artist_name1: "Sherry Lesch",
    video: "https://mario.org",
    description:
      "Amet sapiente voluptatem rerum fugit eius dolorem architecto incidunt.",
    metauri: "http://enoch.biz",
    token_id: 2721,
    chainId: "80001",
    supply: 460,
    royalty: 9527,
    wallet_address: "0xbcf5080ede59f4bd5eabc68bf9cdade81301b92e",
    status: true,
    isEmote: true,
    rid: "rid",
    isPaid: false,
    video_duration: 10,
    category: "Sports",
    likeCount: "0",
    watchCount: "0",
    is_blocked: true,
    view_count: 0,
    view_users: [],
    createdAt: "2024-01-29T11:15:49.473Z",
    updatedAt: "2023-09-22T08:18:44.575Z",
    __v: 0,
  },
  {
    _id: "lucg8jeinvy0pbgkbwn",
    name: "Jasmine Rogahn",
    user_id: "lucg8jeivhzi0gxq99f",
    artist_name1: "Dan Gutmann",
    video: "https://isac.name",
    description: "Labore quae minima eum.",
    metauri: "https://freeman.info",
    token_id: 7284,
    chainId: "80001",
    supply: 91,
    royalty: 6434,
    wallet_address: "0xfe58ed8bfe2e35aaaceeff788b9dfc0c5fe779ac",
    status: false,
    isEmote: false,
    rid: "rid",
    isPaid: false,
    video_duration: 27,
    category: "Computers",
    likeCount: "0",
    watchCount: "0",
    is_blocked: true,
    view_count: 0,
    view_users: [],
    createdAt: "2023-04-06T12:29:03.343Z",
    updatedAt: "2023-08-25T14:29:03.029Z",
    __v: 0,
  },
  {
    _id: "lucg8jeiurrulh4w27",
    name: "Jan Runolfsson",
    user_id: "lucg8jeiurbfnuad46",
    artist_name1: "Ms. Clinton Kunze",
    video: "http://samson.org",
    description: "Minus et provident maxime cum fugiat unde.",
    metauri: "https://mariah.org",
    token_id: 4935,
    chainId: "80001",
    supply: 282,
    royalty: 9688,
    wallet_address: "0x70a8bb9daeb66dd4ad151e53dec7163cee0ec5ac",
    status: true,
    isEmote: false,
    rid: "rid",
    isPaid: false,
    video_duration: 54,
    category: "Games",
    likeCount: "0",
    watchCount: "0",
    is_blocked: true,
    view_count: 0,
    view_users: [],
    createdAt: "2024-01-22T16:52:07.995Z",
    updatedAt: "2024-07-15T23:05:07.655Z",
    __v: 0,
  },
  {
    _id: "lucg8jejgqbbv1e8bb9",
    name: "Melody Schaden",
    user_id: "lucg8jejubgf3aykkaj",
    artist_name1: "Sharon Davis",
    video: "https://matilde.com",
    description: "Qui consequatur atque et qui corrupti nemo.",
    metauri: "http://lina.name",
    token_id: 4407,
    chainId: "80001",
    supply: 662,
    royalty: 3239,
    wallet_address: "0xfa9f6f926bfd8dd8c6eec9f2ddbbbfc1005e1eef",
    status: true,
    isEmote: false,
    rid: "rid",
    isPaid: true,
    video_duration: 34,
    category: "Garden",
    likeCount: "0",
    watchCount: "0",
    is_blocked: false,
    view_count: 0,
    view_users: [],
    createdAt: "2023-10-23T18:49:40.835Z",
    updatedAt: "2023-02-10T04:08:17.443Z",
    __v: 0,
  },
  {
    _id: "lucg8jejnh6jsdx2tq",
    name: "Gwendolyn Lindgren V",
    user_id: "lucg8jejswkqx39r4j",
    artist_name1: "Luis Gerhold",
    video: "https://emerson.name",
    description: "Qui voluptatem expedita ut.",
    metauri: "http://rosalind.net",
    token_id: 4826,
    chainId: "80001",
    supply: 713,
    royalty: 5953,
    wallet_address: "0xd2aefe9aa1bb3dbd3ccbb6a98ae3451ecefde112",
    status: false,
    isEmote: true,
    rid: "rid",
    isPaid: true,
    video_duration: 44,
    category: "Electronics",
    likeCount: "0",
    watchCount: "0",
    is_blocked: true,
    view_count: 0,
    view_users: [],
    createdAt: "2024-03-17T12:23:55.452Z",
    updatedAt: "2024-05-16T06:48:56.371Z",
    __v: 0,
  },
  {
    _id: "lucg8jejj7101jojaxr",
    name: "Dr. Mattie Raynor",
    user_id: "lucg8jejpn8ynibppwg",
    artist_name1: "Darrell Reinger",
    video: "http://elody.org",
    description: "Architecto qui debitis a aut nam.",
    metauri: "http://alberto.com",
    token_id: 4694,
    chainId: "80001",
    supply: 535,
    royalty: 7306,
    wallet_address: "0xe224f3790aa7abddaf3db6cc8eeb8b4d29caeb78",
    status: false,
    isEmote: true,
    rid: "rid",
    isPaid: false,
    video_duration: 51,
    category: "Movies",
    likeCount: "0",
    watchCount: "0",
    is_blocked: true,
    view_count: 0,
    view_users: [],
    createdAt: "2023-11-28T15:57:14.394Z",
    updatedAt: "2024-04-05T08:28:10.723Z",
    __v: 0,
  },
  {
    _id: "lucg8jejvz4g9te9tr",
    name: "Renee Buckridge",
    user_id: "lucg8jej432rnsmjf0p",
    artist_name1: "Roxanne Gutmann",
    video: "http://gretchen.name",
    description: "Voluptatem in animi et rerum.",
    metauri: "https://roberta.info",
    token_id: 7963,
    chainId: "80001",
    supply: 262,
    royalty: 102,
    wallet_address: "0xda8ccb3c2defb66fa6ecaa03e1fcd38d5be3ff7a",
    status: false,
    isEmote: true,
    rid: "rid",
    isPaid: true,
    video_duration: 58,
    category: "Jewelery",
    likeCount: "0",
    watchCount: "0",
    is_blocked: true,
    view_count: 0,
    view_users: [],
    createdAt: "2023-01-28T06:42:13.838Z",
    updatedAt: "2023-04-04T00:52:23.638Z",
    __v: 0,
  },
  {
    _id: "lucg8jeju9cyjoylq8q",
    name: "Kendra Davis",
    user_id: "lucg8jej2ba140xhk36",
    artist_name1: "Bernard Mills",
    video: "http://amaya.info",
    description: "Asperiores quas doloribus.",
    metauri: "https://alexis.biz",
    token_id: 4547,
    chainId: "80001",
    supply: 246,
    royalty: 6980,
    wallet_address: "0xcbf77bbcdafde2497543fbbd996dcfc8f25ade15",
    status: false,
    isEmote: false,
    rid: "rid",
    isPaid: true,
    video_duration: 24,
    category: "Computers",
    likeCount: "0",
    watchCount: "0",
    is_blocked: false,
    view_count: 0,
    view_users: [],
    createdAt: "2023-04-25T07:01:29.868Z",
    updatedAt: "2024-09-25T14:45:05.388Z",
    __v: 0,
  },
  {
    _id: "lucg8jejpfnwwiuyht",
    name: "Lloyd Tremblay",
    user_id: "lucg8jej347qbrhnard",
    artist_name1: "Cesar Kirlin Jr.",
    video: "https://filomena.org",
    description: "Unde ut eos.",
    metauri: "https://sven.org",
    token_id: 8206,
    chainId: "80001",
    supply: 827,
    royalty: 5154,
    wallet_address: "0x93dbfcfddcb4cbef2e300dd8364c09fef90f5c0f",
    status: true,
    isEmote: true,
    rid: "rid",
    isPaid: true,
    video_duration: 5,
    category: "Sports",
    likeCount: "0",
    watchCount: "0",
    is_blocked: true,
    view_count: 0,
    view_users: [],
    createdAt: "2023-06-12T22:42:41.434Z",
    updatedAt: "2023-12-30T01:23:27.206Z",
    __v: 0,
  },
  {
    _id: "lucg8jej6o22f4wem22",
    name: "Ethel Bednar",
    user_id: "lucg8jejsrz2r5obzb",
    artist_name1: "Sidney Brakus",
    video: "https://clarabelle.info",
    description: "Sapiente reiciendis ullam.",
    metauri: "http://nickolas.com",
    token_id: 2331,
    chainId: "80001",
    supply: 600,
    royalty: 7954,
    wallet_address: "0xdc73ac6a7594cdd3fe8a6ed400b04a6752ee0985",
    status: true,
    isEmote: false,
    rid: "rid",
    isPaid: true,
    video_duration: 43,
    category: "Automotive",
    likeCount: "0",
    watchCount: "0",
    is_blocked: true,
    view_count: 0,
    view_users: [],
    createdAt: "2023-08-07T09:48:29.648Z",
    updatedAt: "2023-09-21T18:45:22.880Z",
    __v: 0,
  },
  {
    _id: "lucg8jek6j3l3mb9ntk",
    name: "Amber Beier",
    user_id: "lucg8jek00o0v4m85nunl",
    artist_name1: "Kathryn Mann DVM",
    video: "http://annabell.com",
    description: "Quidem minus non quia veritatis.",
    metauri: "http://muriel.biz",
    token_id: 729,
    chainId: "80001",
    supply: 557,
    royalty: 2083,
    wallet_address: "0x8021b5defdcfccb8e69373c9e6bdab93bcb2a3b2",
    status: false,
    isEmote: false,
    rid: "rid",
    isPaid: false,
    video_duration: 8,
    category: "Kids",
    likeCount: "0",
    watchCount: "0",
    is_blocked: true,
    view_count: 0,
    view_users: [],
    createdAt: "2023-07-28T00:56:43.296Z",
    updatedAt: "2023-07-16T03:24:14.762Z",
    __v: 0,
  },
  {
    _id: "lucg8jek1elodcqpv8o",
    name: "Ted Weber",
    user_id: "lucg8jekvskcs3v1x8",
    artist_name1: "Mabel Farrell",
    video: "https://jerrold.org",
    description: "Qui autem et et quis vel quam nemo illo.",
    metauri: "https://jasen.com",
    token_id: 1886,
    chainId: "80001",
    supply: 508,
    royalty: 3580,
    wallet_address: "0xfca4119b60a5e4cf171c07bae8e8d0bd22c6f87f",
    status: true,
    isEmote: true,
    rid: "rid",
    isPaid: true,
    video_duration: 31,
    category: "Garden",
    likeCount: "0",
    watchCount: "0",
    is_blocked: true,
    view_count: 0,
    view_users: [],
    createdAt: "2023-05-26T07:42:53.148Z",
    updatedAt: "2024-10-24T01:01:27.691Z",
    __v: 0,
  },
  {
    _id: "lucg8jekodqwiss3ur",
    name: "Mr. Lynn Ebert",
    user_id: "lucg8jekqbh553jdjta",
    artist_name1: "Grady Halvorson",
    video: "https://samson.name",
    description: "Cupiditate quasi qui voluptatem ut aut eos sint quod modi.",
    metauri: "https://danny.org",
    token_id: 868,
    chainId: "80001",
    supply: 213,
    royalty: 8066,
    wallet_address: "0x6a1f0bbd5f9e9dcd576ea1a8daef5beb09d6c238",
    status: false,
    isEmote: false,
    rid: "rid",
    isPaid: true,
    video_duration: 7,
    category: "Automotive",
    likeCount: "0",
    watchCount: "0",
    is_blocked: false,
    view_count: 0,
    view_users: [],
    createdAt: "2023-10-07T06:35:13.606Z",
    updatedAt: "2023-05-02T22:37:33.767Z",
    __v: 0,
  },
];

export const topNfts = [
  {
    _id: "hgwfcRQ0UU6LeQjmpwKsHbwY",
    nft_id: "lucg8jdpl4q8est2dn",
    duration: 410795675,
    nft_link: "http://localhost:3000/collections/1zzqzKp9XnwCTrMz3GbRLTyn",
    serial_number: 1,
    createdAt: "2023-05-07T07:09:43.084Z",
    updatedAt: "2024-05-01T20:09:10.538Z",
    __v: 0,
  },
  {
    _id: "idfOxCxvhh8tbKWhtnSoioO9",
    nft_id: "lucg8jeesnwsl8mgf7",
    duration: 263225420,
    nft_link: "http://localhost:3000/collections/EPnvqqW9eMJEvcemi7GB97WP",
    serial_number: 2,
    createdAt: "2023-07-17T05:15:20.117Z",
    updatedAt: "2023-10-24T08:17:58.696Z",
    __v: 0,
  },
  {
    _id: "d5lPFRsF3Ov4lv84Us2Pxx90",
    nft_id: "lucg8jeeorsqjgc5rvb",
    duration: 287384940,
    nft_link: "http://localhost:3000/collections/xtDuKkbiMKWVZbtlhONQmtSQ",
    serial_number: 3,
    createdAt: "2024-02-24T00:58:13.572Z",
    updatedAt: "2024-02-02T08:50:38.233Z",
    __v: 0,
  },
  {
    _id: "63vsrpV8lv4IHxVGtUEJUFGS",
    nft_id: "lucg8jefpe2wwg0psb",
    duration: 171438387,
    nft_link: "http://localhost:3000/collections/QDgIv5zT5I226F62Cm15lqiZ",
    serial_number: 4,
    createdAt: "2024-05-03T22:20:45.715Z",
    updatedAt: "2023-06-04T01:02:11.476Z",
    __v: 0,
  },
  {
    _id: "qSGtlRfH0j442UMp3Feidu2d",
    nft_id: "lucg8jefbo3givb0mph",
    duration: 649476252,
    nft_link: "http://localhost:3000/collections/qht9gL4vEQFZycO57ECsqlc0",
    serial_number: 5,
    createdAt: "2024-02-20T20:47:55.636Z",
    updatedAt: "2023-05-09T03:51:33.791Z",
    __v: 0,
  },
  {
    _id: "0HCY8Uo2QwUaDDVgU65B3Xu2",
    nft_id: "lucg8jefcafu222r44f",
    duration: 88982043,
    nft_link: "http://localhost:3000/collections/BzP20zjzQYMGGLYvmCYVPfky",
    serial_number: 6,
    createdAt: "2023-02-03T17:50:50.856Z",
    updatedAt: "2024-07-31T00:46:50.313Z",
    __v: 0,
  },
  {
    _id: "YA4ihkR0ouz4vmXuhSP6Mpo7",
    nft_id: "lucg8jeg1d9gfh6cvwl",
    duration: 440596685,
    nft_link: "http://localhost:3000/collections/RX46NDDmuN4rGah6ratJUQ8z",
    serial_number: 7,
    createdAt: "2023-12-02T19:24:32.561Z",
    updatedAt: "2023-12-01T10:56:42.769Z",
    __v: 0,
  },
  {
    _id: "kGlzuGv8tCzj1wS3gYvHcF0U",
    nft_id: "lucg8jeiz71glmw6mcf",
    duration: 77995165,
    nft_link: "http://localhost:3000/collections/OuqSfVjB0JwLMYJKSo50ETh5",
    serial_number: 8,
    createdAt: "2024-03-20T12:14:18.448Z",
    updatedAt: "2023-05-27T23:03:41.329Z",
    __v: 0,
  },
  {
    _id: "4iO1M6iKZPlZrplfRmqa1bpy",
    nft_id: "lucg8jeinvy0pbgkbwn",
    duration: 511763843,
    nft_link: "http://localhost:3000/collections/emy7OvRwqijeG2U4REELHBnu",
    serial_number: 9,
    createdAt: "2023-11-16T15:56:41.021Z",
    updatedAt: "2023-11-10T22:37:09.404Z",
    __v: 0,
  },
  {
    _id: "pHHb4SD0uTiY1AAIx08NUZhC",
    nft_id: "lucg8jeiurrulh4w27",
    duration: 41124021,
    nft_link: "http://localhost:3000/collections/iAV9jl0141ZnpyDYN8uiqoQ9",
    serial_number: 10,
    createdAt: "2024-01-23T06:21:43.659Z",
    updatedAt: "2023-11-17T13:06:42.626Z",
    __v: 0,
  },
  {
    _id: "nZqUOxBGNFqzUkZd0GgtuSoI",
    nft_id: "lucg8jejgqbbv1e8bb9",
    duration: 172804372,
    nft_link: "http://localhost:3000/collections/kAvuPbHM9OfUjt4PGynrHStD",
    serial_number: 11,
    createdAt: "2024-03-03T04:34:56.284Z",
    updatedAt: "2024-01-14T00:09:57.548Z",
    __v: 0,
  },
  {
    _id: "lEUjc6znNIPaMVtZisssr93U",
    nft_id: "lucg8jejnh6jsdx2tq",
    duration: 934432447,
    nft_link: "http://localhost:3000/collections/uSyz9ZzgoQMPOSsBCfxNwryb",
    serial_number: 12,
    createdAt: "2024-08-02T04:08:52.495Z",
    updatedAt: "2024-06-14T00:22:56.132Z",
    __v: 0,
  },
  {
    _id: "Ivm1tvhCPJAhAjg7syMmWzsn",
    nft_id: "lucg8jejj7101jojaxr",
    duration: 456429607,
    nft_link: "http://localhost:3000/collections/XMM9qmJMZ9cap3hxvsFSRhP8",
    serial_number: 13,
    createdAt: "2023-12-11T19:01:53.941Z",
    updatedAt: "2024-12-16T03:41:57.847Z",
    __v: 0,
  },
  {
    _id: "IruE1uN7Rh5FPSQQX54ipsVu",
    nft_id: "lucg8jejvz4g9te9tr",
    duration: 625575822,
    nft_link: "http://localhost:3000/collections/yCEjVCrAFLoWxrMvnPnDoXNJ",
    serial_number: 14,
    createdAt: "2024-09-25T21:42:41.201Z",
    updatedAt: "2023-06-27T05:40:24.193Z",
    __v: 0,
  },
  {
    _id: "rh7S1IEskOhFI7eunFE66Wu8",
    nft_id: "lucg8jeju9cyjoylq8q",
    duration: 118046933,
    nft_link: "http://localhost:3000/collections/v3O4FGDaHEOXlaVvJ6kN2zIa",
    serial_number: 15,
    createdAt: "2023-09-18T10:50:27.808Z",
    updatedAt: "2023-12-25T09:00:16.973Z",
    __v: 0,
  },
  {
    _id: "Ho0SU8AzQ3RcJxnChAn4uEwQ",
    nft_id: "lucg8jejpfnwwiuyht",
    duration: 251160508,
    nft_link: "http://localhost:3000/collections/xIx7tutFNrvJrEGDfVCyn15Y",
    serial_number: 16,
    createdAt: "2023-05-17T05:44:33.413Z",
    updatedAt: "2023-04-29T22:27:57.345Z",
    __v: 0,
  },
  {
    _id: "9wBZ9bV10Y9kLyfGGSL8UW9E",
    nft_id: "lucg8jej6o22f4wem22",
    duration: 475108854,
    nft_link: "http://localhost:3000/collections/1p1Ru0X8P8VnUDwmfMuixOIc",
    serial_number: 17,
    createdAt: "2023-05-24T12:32:29.821Z",
    updatedAt: "2024-12-02T10:35:31.124Z",
    __v: 0,
  },
  {
    _id: "oxbyIGesbtrBXz0JTQVzuU90",
    nft_id: "lucg8jek6j3l3mb9ntk",
    duration: 757490884,
    nft_link: "http://localhost:3000/collections/mqRgY7nU2QsQyzm8G3Br0piz",
    serial_number: 18,
    createdAt: "2023-07-11T20:34:53.253Z",
    updatedAt: "2023-07-30T15:00:46.355Z",
    __v: 0,
  },
  {
    _id: "dmrw21f8g4rkVIUq4LojvofI",
    nft_id: "lucg8jek1elodcqpv8o",
    duration: 872110244,
    nft_link: "http://localhost:3000/collections/zvY87OTuvhKjIl5Wht1psPJo",
    serial_number: 19,
    createdAt: "2023-12-21T09:36:53.877Z",
    updatedAt: "2024-05-18T17:08:16.363Z",
    __v: 0,
  },
  {
    _id: "JtqDdUUINoHKIK0JVFZWQisK",
    nft_id: "lucg8jekodqwiss3ur",
    duration: 701660687,
    nft_link: "http://localhost:3000/collections/Bo51rzlGjUBIiXLDv7oEXxr1",
    serial_number: 20,
    createdAt: "2024-03-07T08:18:57.022Z",
    updatedAt: "2024-12-23T16:02:08.623Z",
    __v: 0,
  },
];

export const users = [
  {
    _id: "da0b74ff90d2-4020-8ff8-5cece14951cd",
    full_name: "E8eAipgu",
    email: "4DpBAJpOM0@yahoo.com",
    user_name: "4dwqPE",
    password: "$2a$08$your-hashed-password",
    phone_number: "+1 916-127-4036",
    user_address: "0xeb01f3d3e2e56ebe3ee40ee0d02656a9edb8a1e6",
    is_deleted: false,
    notes: [],
    createdAt: "2024-03-29T09:41:15.382Z",
    updatedAt: "2024-03-29T09:41:15.411Z",
    __v: 0,
    bio: null,
    country: null,
    profileImg: "placeholder.png",
    is_login: false,
  },
  {
    _id: "9bea0a09e42a-4714-9174-5a7afe65f464",
    full_name: "tQ91Zeke",
    email: "TSZz5nLlAm@gmail.com",
    user_name: "phpBF0",
    password: "$2a$08$your-hashed-password",
    phone_number: "+1 508-754-2874",
    user_address: "0xef83dab59cb11dc665bd7149d532708ffc17503e",
    is_deleted: false,
    notes: [],
    createdAt: "2024-03-29T09:41:15.411Z",
    updatedAt: "2024-03-29T09:41:15.411Z",
    __v: 0,
    bio: null,
    country: null,
    profileImg: "placeholder.png",
    is_login: false,
  },
  {
    _id: "bb6a12c76bbb-42e4-90f1-b3b33c18cb8c",
    full_name: "xhUcqaJj",
    email: "l7cMFAToc3@hotmail.com",
    user_name: "dnP9v2",
    password: "$2a$08$your-hashed-password",
    phone_number: "+1 784-277-6457",
    user_address: "0xda977ec8a0032f807e694157066b3ac21fd61847",
    is_deleted: false,
    notes: [],
    createdAt: "2024-03-29T09:41:15.411Z",
    updatedAt: "2024-03-29T09:41:15.411Z",
    __v: 0,
    bio: null,
    country: null,
    profileImg: "placeholder.png",
    is_login: false,
  },
  {
    _id: "8191b3f8c4d5-4b71-a98c-cb9b7078d9ec",
    full_name: "rdLiIBMe",
    email: "f1XqOxW6if@yahoo.com",
    user_name: "ZIiySt",
    password: "$2a$08$your-hashed-password",
    phone_number: "+1 887-187-4724",
    user_address: "0x6ce34b26c60c1692fb2563557df96aebf711c448",
    is_deleted: true,
    notes: [],
    createdAt: "2024-03-29T09:41:15.411Z",
    updatedAt: "2024-03-29T09:41:15.411Z",
    __v: 0,
    bio: null,
    country: null,
    profileImg: "placeholder.png",
    is_login: false,
  },
  {
    _id: "61d7109e84c8-4fa5-990d-294e680d4f0f",
    full_name: "Vp6YvHqT",
    email: "hIOcZr0dm1@yahoo.com",
    user_name: "3WxY0x",
    password: "$2a$08$your-hashed-password",
    phone_number: "+1 498-395-3918",
    user_address: "0xfc6cec00d7791743c6735f23ed40246016d46b9e",
    is_deleted: false,
    notes: [],
    createdAt: "2024-04-29T09:41:15.411Z",
    updatedAt: "2024-04-29T09:41:15.411Z",
    __v: 0,
    bio: null,
    country: null,
    profileImg: "placeholder.png",
    is_login: false,
  },
  {
    _id: "cdfd99171f89-4ea5-8217-0ef8d00b6938",
    full_name: "Rz1eCP10",
    email: "kxwj9Bc4ZG@yahoo.com",
    user_name: "TLfdkM",
    password: "$2a$08$your-hashed-password",
    phone_number: "+1 706-461-4030",
    user_address: "0x9af97e64e9e55bc01df0f0983d56e966b935d49a",
    is_deleted: false,
    notes: [],
    createdAt: "2024-04-29T09:41:15.411Z",
    updatedAt: "2024-04-29T09:41:15.411Z",
    __v: 0,
    bio: null,
    country: null,
    profileImg: "placeholder.png",
    is_login: false,
  },
  {
    _id: "c9fd4618b4f3-418b-a095-f47437b0acc0",
    full_name: "ew1jFjsN",
    email: "1NaYmjwz5P@gmail.com",
    user_name: "uqcPMB",
    password: "$2a$08$your-hashed-password",
    phone_number: "+1 390-104-2340",
    user_address: "0xbd2412dd127d182774f60344c74f9a5d890f0035",
    is_deleted: true,
    notes: [],
    createdAt: "2024-04-29T09:41:15.411Z",
    updatedAt: "2024-04-29T09:41:15.411Z",
    __v: 0,
    bio: null,
    country: null,
    profileImg: "placeholder.png",
    is_login: false,
  },
  {
    _id: "dfb89f9aeda9-4ee0-a524-cdb270a2f6c5",
    full_name: "3zjJkhhh",
    email: "eoCbRxiQBA@gmail.com",
    user_name: "eEd10N",
    password: "$2a$08$your-hashed-password",
    phone_number: "+1 897-291-9178",
    user_address: "0xece4bd876ad0b7cfce3ef02356595fc66d77ce78",
    is_deleted: true,
    notes: [],
    createdAt: "2024-03-29T09:41:15.411Z",
    updatedAt: "2024-03-29T09:41:15.411Z",
    __v: 0,
    bio: null,
    country: null,
    profileImg: "placeholder.png",
    is_login: false,
  },
  {
    _id: "2a0c5782caa7-4389-8942-04f0a50a055b",
    full_name: "qkOcj3Yv",
    email: "hX0djFMaWA@hotmail.com",
    user_name: "VTxqBw",
    password: "$2a$08$your-hashed-password",
    phone_number: "+1 948-400-5432",
    user_address: "0x2ef8c378ecc619e360f5a862d1a011cba1f88cad",
    is_deleted: true,
    notes: [],
    createdAt: "2024-03-29T09:41:15.412Z",
    updatedAt: "2024-03-29T09:41:15.412Z",
    __v: 0,
    bio: null,
    country: null,
    profileImg: "placeholder.png",
    is_login: false,
  },
  {
    _id: "77416c7c56bb-4349-97b3-895a0b1050ca",
    full_name: "Tp1zYQ9Q",
    email: "ZLlvBuBODH@hotmail.com",
    user_name: "NLdTKP",
    password: "$2a$08$your-hashed-password",
    phone_number: "+1 826-139-2236",
    user_address: "0xbff8c36fb0e65871c4ac93b1dba2211c1e06e428",
    is_deleted: false,
    notes: [],
    createdAt: "2024-03-29T09:41:15.412Z",
    updatedAt: "2024-03-29T09:41:15.412Z",
    __v: 0,
    bio: null,
    country: null,
    profileImg: "placeholder.png",
    is_login: false,
  },
  {
    _id: "98337cd0c15f-4290-8b24-3b38f1dbfaa3",
    full_name: "03P6IFXB",
    email: "Tm909MasTM@yahoo.com",
    user_name: "90cM3c",
    password: "$2a$08$your-hashed-password",
    phone_number: "+1 584-128-9563",
    user_address: "0x74ac1ce1d5c009748a7bc18edb23041aee4cfcaf",
    is_deleted: false,
    notes: [],
    createdAt: "2024-03-29T09:41:15.412Z",
    updatedAt: "2024-03-29T09:41:15.412Z",
    __v: 0,
    bio: null,
    country: null,
    profileImg: "placeholder.png",
    is_login: false,
  },
  {
    _id: "f7664abdf2f3-45a6-a051-3b37c5c5ac96",
    full_name: "sxrrq2Cr",
    email: "QI52HbGquc@yahoo.com",
    user_name: "CSfv6o",
    password: "$2a$08$your-hashed-password",
    phone_number: "+1 586-857-9441",
    user_address: "0x26f6b2cec29f5c8326973e6ccb60e7395bada550",
    is_deleted: true,
    notes: [],
    createdAt: "2024-03-29T09:41:15.413Z",
    updatedAt: "2024-03-29T09:41:15.413Z",
    __v: 0,
    bio: null,
    country: null,
    profileImg: "placeholder.png",
    is_login: false,
  },
  {
    _id: "828a249efe67-4acf-9cac-3f3c4d3c27c1",
    full_name: "U5Keoncs",
    email: "CSs5UWaxPR@hotmail.com",
    user_name: "Dl1ppO",
    password: "$2a$08$your-hashed-password",
    phone_number: "+1 144-943-8397",
    user_address: "0x03156222c816c33dd179b1caf88c8287dce800bd",
    is_deleted: true,
    notes: [],
    createdAt: "2024-03-29T09:41:15.413Z",
    updatedAt: "2024-03-29T09:41:15.413Z",
    __v: 0,
    bio: null,
    country: null,
    profileImg: "placeholder.png",
    is_login: false,
  },
  {
    _id: "e4078fba4728-45eb-87b8-3e91eaabe95e",
    full_name: "iHdWPy9R",
    email: "J85KAtWowv@hotmail.com",
    user_name: "CXoJPV",
    password: "$2a$08$your-hashed-password",
    phone_number: "+1 675-280-3496",
    user_address: "0x0742ba6e4526cd83ccf987555901b1bd8ca91aa1",
    is_deleted: false,
    notes: [],
    createdAt: "2024-03-29T09:41:15.417Z",
    updatedAt: "2024-03-29T09:41:15.417Z",
    __v: 0,
    bio: null,
    country: null,
    profileImg: "placeholder.png",
    is_login: false,
  },
  {
    _id: "12319299d47c-4243-b00f-2b36a0a35780",
    full_name: "HRrbKoEw",
    email: "TN9pWk3mWH@gmail.com",
    user_name: "xVcQ1V",
    password: "$2a$08$your-hashed-password",
    phone_number: "+1 383-352-7206",
    user_address: "0x4c096ff7d41671c28ac5e7936ec957a50e7abfdb",
    is_deleted: true,
    notes: [],
    createdAt: "2024-03-29T09:41:15.417Z",
    updatedAt: "2024-03-29T09:41:15.417Z",
    __v: 0,
    bio: null,
    country: null,
    profileImg: "placeholder.png",
    is_login: false,
  },
  {
    _id: "838ebc25aa41-466e-b5e9-c136f6382b1a",
    full_name: "pch65hGD",
    email: "dDQhRqX9J2@gmail.com",
    user_name: "yT3q4z",
    password: "$2a$08$your-hashed-password",
    phone_number: "+1 282-694-2814",
    user_address: "0xd29cc789b6ea3725611455e0f736d79b3087dea3",
    is_deleted: false,
    notes: [],
    createdAt: "2024-03-29T09:41:15.417Z",
    updatedAt: "2024-03-29T09:41:15.417Z",
    __v: 0,
    bio: null,
    country: null,
    profileImg: "placeholder.png",
    is_login: false,
  },
  {
    _id: "68256d9c4b34-431c-bb15-5bcc044dcb0b",
    full_name: "IBAWUdDv",
    email: "3OCUjso0rI@hotmail.com",
    user_name: "vCsEnF",
    password: "$2a$08$your-hashed-password",
    phone_number: "+1 671-555-2830",
    user_address: "0x342596c4e0b28c5995c6a4cd2dad35c56a890346",
    is_deleted: true,
    notes: [],
    createdAt: "2024-03-29T09:41:15.417Z",
    updatedAt: "2024-03-29T09:41:15.417Z",
    __v: 0,
    bio: null,
    country: null,
    profileImg: "placeholder.png",
    is_login: false,
  },
  {
    _id: "be19390ac0e1-408b-8db8-e0ad91110036",
    full_name: "iFF9dF85",
    email: "kMIk2S2CeQ@yahoo.com",
    user_name: "J9zG73",
    password: "$2a$08$your-hashed-password",
    phone_number: "+1 549-605-1001",
    user_address: "0x683e3a2c3889393e3edc3e98ee452c020bf95d4c",
    is_deleted: false,
    notes: [],
    createdAt: "2024-03-29T09:41:15.417Z",
    updatedAt: "2024-03-29T09:41:15.417Z",
    __v: 0,
    bio: null,
    country: null,
    profileImg: "placeholder.png",
    is_login: false,
  },
  {
    _id: "0f0fcf15bbe4-4a10-9e17-a63cefdcf593",
    full_name: "IumJCbMf",
    email: "cj47VlXcBl@yahoo.com",
    user_name: "NOcY9O",
    password: "$2a$08$your-hashed-password",
    phone_number: "+1 408-974-2831",
    user_address: "0x10425d05d3185d30232660a00fad853a9e7366d5",
    is_deleted: false,
    notes: [],
    createdAt: "2024-03-29T09:41:15.417Z",
    updatedAt: "2024-03-29T09:41:15.417Z",
    __v: 0,
    bio: null,
    country: null,
    profileImg: "placeholder.png",
    is_login: false,
  },
  {
    _id: "78d03a7b7543-4e65-bd7c-610e587292ba",
    full_name: "ARI06zNM",
    email: "SKpG6HxKec@gmail.com",
    user_name: "oJqQz1",
    password: "$2a$08$your-hashed-password",
    phone_number: "+1 206-881-8648",
    user_address: "0x75f1af095b726b87e4fe4c7ae6c9a50ec74462af",
    is_deleted: true,
    notes: [],
    createdAt: "2024-03-29T09:41:15.417Z",
    updatedAt: "2024-03-29T09:41:15.417Z",
    __v: 0,
    bio: null,
    country: null,
    profileImg: "placeholder.png",
    is_login: false,
  },
];

export const visits = [
  {
    _id: "cf9c46f3-a070-47d7-94de-29feb2a2a1af",
    ip_adress: "160.17.55.254",
    timestamp: "2024-05-09T12:53:40.955Z",
    __v: 0,
  },
  {
    _id: "36804ee7-44e1-4a6d-af6d-ff2c355e784d",
    ip_adress: "252.99.47.115",
    timestamp: "2024-01-06T14:02:45.276Z",
    __v: 0,
  },
  {
    _id: "ab95a46b-6dbe-46d1-a778-415bbab8781b",
    ip_adress: "199.6.159.221",
    timestamp: "2023-10-06T03:35:46.093Z",
    __v: 0,
  },
  {
    _id: "6d89f8b5-024c-4867-8ca0-f732abe006cc",
    ip_adress: "22.110.233.125",
    timestamp: "2023-12-18T06:35:16.815Z",
    __v: 0,
  },
  {
    _id: "0cfdcf89-7307-4a1c-ab31-2a0b056f85d0",
    ip_adress: "146.106.38.238",
    timestamp: "2023-08-28T23:08:45.144Z",
    __v: 0,
  },
  {
    _id: "4e815372-9843-457a-a468-b5b0c975eaa5",
    ip_adress: "6.160.40.170",
    timestamp: "2023-01-30T21:44:27.220Z",
    __v: 0,
  },
  {
    _id: "0ea27a4a-5207-4175-b75d-3b1f09c116da",
    ip_adress: "131.244.175.82",
    timestamp: "2023-03-20T14:10:37.815Z",
    __v: 0,
  },
  {
    _id: "e53ec963-ae95-4b09-8ac0-8e960b7f6925",
    ip_adress: "174.46.83.250",
    timestamp: "2023-12-13T16:43:11.375Z",
    __v: 0,
  },
  {
    _id: "44c30349-c5d3-4fb2-bd53-f0ab93a6b123",
    ip_adress: "81.159.50.186",
    timestamp: "2023-03-14T12:54:58.346Z",
    __v: 0,
  },
  {
    _id: "e4d486ae-2649-4869-80b4-0bea4f57ca70",
    ip_adress: "25.162.179.194",
    timestamp: "2023-10-01T08:30:57.500Z",
    __v: 0,
  },
  {
    _id: "a657703c-f941-4e69-bc49-4d95a067869a",
    ip_adress: "90.171.190.33",
    timestamp: "2023-01-19T12:42:27.892Z",
    __v: 0,
  },
  {
    _id: "0c4d5ccf-6a0e-4cae-869a-462a0d8e7763",
    ip_adress: "5.165.198.137",
    timestamp: "2023-12-06T15:32:31.100Z",
    __v: 0,
  },
  {
    _id: "40e9b448-72e5-454c-9bfd-e1a4dbebae0e",
    ip_adress: "190.130.79.12",
    timestamp: "2023-01-25T22:58:24.570Z",
    __v: 0,
  },
  {
    _id: "368e0d9c-700f-4d1a-a1b1-d7a8c593a5c5",
    ip_adress: "135.237.228.21",
    timestamp: "2023-10-07T04:42:00.718Z",
    __v: 0,
  },
  {
    _id: "9e6c497c-99e0-46cb-b580-2c3a310828ea",
    ip_adress: "53.115.153.223",
    timestamp: "2023-05-10T10:02:08.763Z",
    __v: 0,
  },
  {
    _id: "de3930a0-cb38-4fba-a5a3-5a008b1f902f",
    ip_adress: "127.217.32.89",
    timestamp: "2023-04-23T06:07:28.311Z",
    __v: 0,
  },
  {
    _id: "015cd3f3-cd4c-4862-9d33-e36a5ec4af9b",
    ip_adress: "63.132.189.183",
    timestamp: "2023-09-16T01:59:19.222Z",
    __v: 0,
  },
  {
    _id: "49944bcd-1979-432f-a589-07ed87fcf964",
    ip_adress: "188.188.49.209",
    timestamp: "2023-12-10T16:38:48.527Z",
    __v: 0,
  },
  {
    _id: "1780fc81-12d6-4edb-a778-91b1f2951e2b",
    ip_adress: "84.250.140.14",
    timestamp: "2023-03-29T16:30:19.616Z",
    __v: 0,
  },
  {
    _id: "011888ea-904e-45df-947a-10019c00166d",
    ip_adress: "203.3.56.185",
    timestamp: "2023-11-16T22:48:43.644Z",
    __v: 0,
  },
];

export const likes = [
  {
    _id: "Ka25Du956y4KEK89TA2RguO2",
    user_id: "da0b74ff90d2-4020-8ff8-5cece14951cd",
    __v: 0,
    createdAt: "2023-12-13T19:39:52.450Z",
    updatedAt: "2023-07-17T03:26:11.751Z",
    nft_ids: ["lucg8jdpl4q8est2dn", "lucg8jeesnwsl8mgf7"],
  },
  {
    _id: "ownUQZlgYMfY55t5kUaPTTbQ",
    user_id: "9bea0a09e42a-4714-9174-5a7afe65f464",
    __v: 0,
    createdAt: "2023-05-24T11:21:09.427Z",
    updatedAt: "2023-03-09T11:05:32.888Z",
    nft_ids: ["lucg8jeesnwsl8mgf7", "lucg8jeeorsqjgc5rvb"],
  },
  {
    _id: "zPQx4ekxryHvS4VVoICvVfsY",
    user_id: "bb6a12c76bbb-42e4-90f1-b3b33c18cb8c",
    __v: 0,
    createdAt: "2024-08-16T18:49:47.095Z",
    updatedAt: "2024-09-11T00:29:21.929Z",
    nft_ids: ["ZLkceUPc7Ysb7aCkF9jf7ri2", "dizfOwgPU872TFkJ90CULkFy"],
  },
  {
    _id: "bOKob88coaQ4cZNnYjHul2ak",
    user_id: "8191b3f8c4d5-4b71-a98c-cb9b7078d9ec",
    __v: 0,
    createdAt: "2023-03-19T02:41:47.471Z",
    updatedAt: "2024-11-22T23:54:46.542Z",
    nft_ids: ["lucg8jeeorsqjgc5rvb", "lucg8jeesnwsl8mgf7"],
  },
  {
    _id: "vK7DjIlbLTDgodWUrgjoqLSX",
    user_id: "8191b3f8c4d5-4b71-a98c-cb9b7078d9ec",
    __v: 0,
    createdAt: "2023-10-01T10:33:45.779Z",
    updatedAt: "2023-07-18T12:42:59.062Z",
    nft_ids: ["lucg8jefpe2wwg0psb", "lucg8jefbo3givb0mph"],
  },
];
